import React from "react";

export const ContactForm: React.FC = () => {
  return (
    <div
      className="contact-form"
      style={{
        display: "grid",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSd9LAojFdCkZJLf0u1lrO25KIEc-_IeGHAOy-UQiGb6z8w3ug/viewform?embedded=true"
        width="100%"
        height="820px"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Loading…
      </iframe>
    </div>
  );
};
