import React from "react";
import { Layout } from "../components/Layout";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import { FrontPageImagesQuery } from "../../__types__/graphql-types";
import { ContactInfo } from "../components/ContactInfo";
import styled from "styled-components";

// This component loaded in client side because it rely on some functionalities which are not available in server side rendering (e.g window.something)
import loadable from "@loadable/component";
import { ContactForm } from "../components/ContactForm";
const Slider = loadable(() => import("../components/Slider"));

interface Props {
  data: FrontPageImagesQuery;
}

const TextContent = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr;
  }
`;
const TextIntro = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const StandardLogos = styled.div`
  display: flex;
  justify-content: space-around;
  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`;

const ContactFooter = styled.div`
  margin: 1rem 1rem;

  @media (min-width: 768px) {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr;
  }
`;

const Index: React.FC<Props> = ({ data }) => {
  return (
    <Layout>
      {/* <Img fluid={data.top?.childImageSharp?.fluid ?? (undefined as any)} /> */}
      <Slider />
      <TextContent className="margin-left-right">
        <TextIntro>
          <p>
            MAPNA’s ultimate goal is to provide a quality home inspection to
            better assist the buyer’s decision on making a property purchase.
            The vision is to simplify the overall conditions observed during the
            time of inspection to assure the buyer’s understanding of any
            potential expenses ahead and to provide peace of mind from avoiding
            any major costs in future before a decisive decision.
          </p>
          <StandardLogos>
            <Img
              style={{
                flex: "50%",
                margin: "2rem",
                maxWidth: "5rem",
                maxHeight: "4rem",
              }}
              fluid={data.ahit?.childImageSharp?.fluid ?? (undefined as any)}
            />
            <Img
              style={{ flex: "50%", margin: "2rem", maxWidth: "5rem" }}
              fluid={data.nachi?.childImageSharp?.fluid ?? (undefined as any)}
            />
          </StandardLogos>
        </TextIntro>

        <div style={{ marginTop: "1rem" }}>
          <h2>What you will expect to see in our Home inspection report?</h2>
          <ul>
            <li>External Conditions & Surfaces</li>
            <li>Garage, Walls & Driveway</li>
            <li>Foundations, Slabs & Floors</li>
            <li>Roof, Attic, Insulation & Ventilation</li>
            <li>Kitchen & Appliances</li>
            <li>Plumbing & Electrical Systems</li>
            <li>Heating & Cooling Systems</li>
            <li>Walls & Ceilings</li>
          </ul>
        </div>
      </TextContent>
      <ContactFooter>
        <div>
          <h4 style={{ marginTop: "0" }}>
            Contact Us and Schedule an appointment:
          </h4>
          <Img
            style={{ margin: "0 auto", maxWidth: "100%" }}
            fluid={
              data.annisPortrait?.childImageSharp?.fluid ?? (undefined as any)
            }
          />
        </div>
        <ContactForm />
      </ContactFooter>
    </Layout>
  );
};

export const dataQuery = graphql`
  query FrontPageImages {
    top: file(relativePath: { eq: "home-top-01.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 250, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ahit: file(relativePath: { eq: "AHIT_Certified_Logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 250, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    nachi: file(relativePath: { eq: "inter-nachi.png" }) {
      childImageSharp {
        fluid(maxWidth: 250, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    annisPortrait: file(relativePath: { eq: "annis-portrait.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Index;
